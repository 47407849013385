import React from 'react'
import styled from 'styled-components'
import anchorme from 'anchorme'
import {stripMarkup} from './../helper-functions/strip-markup'
import AddressPhone from './address-phone'

export default function Event({
  imageDesktopAlt,
  imageDesktopUrl,
  imageMobileAlt,
  imageMobileUrl,
  imageTabletAlt,
  imageTabletUrl,
  informationBottomLeft,
  informationBottomRight,
  title,
  url,
}) {
  const anchormeOptions = {
    attributes: {
      target: '_blank',
      class: 'detected',
    },
  }

  const informationBottomLeftWithLinks = anchorme({
    input: informationBottomLeft,
    options: anchormeOptions,
  })
  const informationBottomRightWithLinks = anchorme({
    input: informationBottomRight,
    options: anchormeOptions,
  })

  return (
    <StyledComponent>
      {imageMobileUrl && (
        <img src={imageMobileUrl} alt={imageMobileAlt || title} className="exhibition-image exhibition-image--mobile" />
      )}

      {imageTabletUrl && (
        <img src={imageTabletUrl} alt={imageTabletAlt || title} className="exhibition-image exhibition-image--tablet" />
      )}

      {title && <h3 className="title">{title}</h3>}

      {imageDesktopUrl && (
        <img src={imageDesktopUrl} alt={imageDesktopAlt || title} className="exhibition-image exhibition-image--desktop" />
      )}

      {(stripMarkup(informationBottomLeftWithLinks) || stripMarkup(informationBottomRightWithLinks)) && (
        <div className="info-wrapper">
          <div className="info-child info-child--left" dangerouslySetInnerHTML={{__html: informationBottomLeftWithLinks}} />
          <div className="info-child info-child--right" dangerouslySetInnerHTML={{__html: informationBottomRightWithLinks}} />
        </div>
      )}

      {url && (
        <div className="link-wrapper">
          <a href={url} target="_blank" rel="noopener" className="btn btn--primary">
            Visit website
          </a>
        </div>
      )}
    </StyledComponent>
  )
}

const StyledComponent = styled.article`
  margin-bottom: 7rem;

  .title {
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.9rem;
    display: inline-block;
    padding: 0;
    margin-bottom: 2rem;

    @media (min-width: 1000px) {
      margin-bottom: 1rem;
      padding-left: 6rem;
    }
  }

  .exhibition-image {
    height: auto;
    margin: 0 -2rem 2rem -2rem;
    width: calc(100% + (2rem * 2));

    @media (min-width: 1200px) {
      margin: 0 -5rem 1rem -5rem;
      width: calc(100% + (5rem * 2));
    }

    &--mobile {
      display: block;

      @media (min-width: 600px) {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @media (min-width: 600px) {
        display: block;
      }

      @media (min-width: 1000px) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      margin-bottom: 1rem;

      @media (min-width: 1000px) {
        display: block;
      }
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    font-weight: 100;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 0;
    width: 100%;

    @media (min-width: 1000px) {
      flex-direction: row;
      margin-bottom: 1rem;
      padding-left: 6rem;
    }
  }

  .info-child {
    &--left {
      margin-bottom: 2rem;
    }
    &--right {
      text-align: left;

      @media (min-width: 1000px) {
        flex-shrink: 0;
        min-width: 38rem;
        text-align: right;
      }
    }
  }

  .link-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (min-width: 600px) {
      justify-content: flex-start;
    }

    @media (min-width: 1000px) {
      justify-content: flex-end;
    }

    a {
      text-align: center;
      width: 100%;

      @media (min-width: 600px) {
        min-width: 14.8rem;
        width: auto;
      }
    }
  }
`
