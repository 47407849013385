import React from 'react'
import styled from 'styled-components'

export default function AddressPhone({address, venuePhoneNumber}) {
  return (
    <StyledComponent>
      {address && <div className="address" dangerouslySetInnerHTML={{__html: address}} />}
      {venuePhoneNumber && <p className="phone-number">{venuePhoneNumber}</p>}
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  border-left: 4px solid #666;
  font-style: italic;
  padding-left: 2rem;
  margin-bottom: 6vw;

  @media (min-width: 800px) {
    margin-bottom: 4.8rem;
  }

  .phone-number {
    margin-top: 1.5rem;
  }
`
