import React from 'react'
import {graphql} from 'gatsby'
import {format, differenceInDays} from 'date-fns'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import Container from './../components/container'
import PageHeading from './../components/page-heading'
import BodyCopy from './../components/body-copy'
import {stripMarkup} from './../helper-functions/strip-markup'
import Event from './../components/event'

export default props => {
  const {data} = props
  const prismicData = {
    bodyCopy: data.prismicEventsPage.data.body_copy.html,
    noExhibitionsText: data.prismicEventsPage.data.no_exhibitions_text.html,
    pastExhibitions: data.prismicEventsPage.data.past_exhibitions,
    pageHeading: data.prismicEventsPage.data.page_heading.text,
  }
  let events = data.allPrismicEvent.edges.map(event => {
    let startDateParts,
      startDateObj,
      startDateFormatted,
      startDateTimeFormat,
      endDateParts,
      endDateObj,
      endDateFormatted,
      endDateTimeFormat,
      numDays

    if (event.node.data.start_date) {
      startDateParts = event.node.data.start_date.split('-')
      startDateObj = new Date(+startDateParts[2], startDateParts[1] - 1, +startDateParts[0])
      startDateFormatted = format(startDateObj, 'd MMMM yyyy')
      startDateTimeFormat = `${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`
    }
    if (event.node.data.end_date) {
      endDateParts = event.node.data.end_date.split('-')
      endDateObj = new Date(+endDateParts[2], endDateParts[1] - 1, +endDateParts[0])
      endDateFormatted = format(endDateObj, 'd MMMM yyyy')
      endDateTimeFormat = `${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`
    }
    if (event.node.data.start_date && event.node.data.end_date) {
      numDays = differenceInDays(endDateObj, startDateObj) + 1
    } else if (event.node.data.start_date) {
      numDays = 1
    }

    return {
      enabled: event.node.data.enabled,
      endDate: event.node.data.end_date,
      endDateFormatted: endDateFormatted,
      endDateObj: endDateObj,
      endDateTimeFormat: endDateTimeFormat,
      id: event.node.id,
      imageDesktopAlt: event.node.data.image_desktop.alt,
      imageDesktopUrl: event.node.data.image_desktop.url,
      imageMobileAlt: event.node.data.image_mobile.alt,
      imageMobileUrl: event.node.data.image_mobile.url,
      imageTabletAlt: event.node.data.image_tablet.alt,
      imageTabletUrl: event.node.data.image_tablet.url,
      informationBottomLeft: event.node.data.information_bottom_left.html,
      informationBottomRight: event.node.data.information_bottom_right.html,
      numDays: numDays,
      startDate: event.node.data.start_date,
      startDateFormatted: startDateFormatted,
      startDateObj: startDateObj,
      startDateTimeFormat: startDateTimeFormat,
      title: event.node.data.title.text,
      url: event.node.data.url.url,
    }
  })

  events = events.filter(event => event.enabled)
  let pastEvents = []
  if (prismicData.pastExhibitions) {
    pastEvents = [...events]
  }

  const today = Date.now()
  events = events.filter(event => {
    let isInFutureOrToday
    if (event.endDateObj) {
      isInFutureOrToday = differenceInDays(event.endDateObj, today) >= 0
    } else if (event.startDateObj) {
      isInFutureOrToday = differenceInDays(event.startDateObj, today) >= 0
    }
    return isInFutureOrToday
  })

  events = sortBy(events, 'startDateObj')

  pastEvents = pastEvents.filter(event => {
    let isInPast
    if (event.endDateObj) {
      isInPast = differenceInDays(event.endDateObj, today) < 0
    } else if (event.startDateObj) {
      isInPast = differenceInDays(event.startDateObj, today) < 0
    }
    return isInPast
  })
  pastEvents = sortBy(pastEvents, 'startDateObj')

  return (
    <Layout pageName="exhibitions" documentTitlePrefix={prismicData.pageHeading}>
      <PageHeading heading={prismicData.pageHeading} />
      {stripMarkup(prismicData.bodyCopy) && (
        <Container doublePadding>
          <BodyCopy bodyHtml={prismicData.bodyCopy} classModifier="exhibitions" />
        </Container>
      )}
      <Container>
        {events.length > 0 &&
          events.map((event, index) => (
            <Fade key={`exhibition-${index}`}>
              <Event
                endDateFormatted={event.endDateFormatted}
                endDateTimeFormat={event.endDateTimeFormat}
                imageDesktopAlt={event.imageDesktopAlt}
                imageDesktopUrl={event.imageDesktopUrl}
                imageMobileAlt={event.imageMobileAlt}
                imageMobileUrl={event.imageMobileUrl}
                imageTabletAlt={event.imageTabletAlt}
                imageTabletUrl={event.imageTabletUrl}
                informationBottomLeft={event.informationBottomLeft}
                informationBottomRight={event.informationBottomRight}
                numDays={event.numDays}
                startDateFormatted={event.startDateFormatted}
                startDateTimeFormat={event.startDateTimeFormat}
                title={event.title}
                url={event.url}
              />
            </Fade>
          ))}
        {events.length === 0 && <BodyCopy bodyHtml={prismicData.noExhibitionsText} classModifier="no-exhibitions" />}
        {prismicData.pastExhibitions && pastEvents.length > 0 && (
          <Fade>
            <PastExhibitionsHeading className="past-exhibitions-heading">Past exhibitions</PastExhibitionsHeading>
          </Fade>
        )}
        {prismicData.pastExhibitions &&
          pastEvents.length > 0 &&
          pastEvents.map((event, index) => (
            <Fade key={`exhibition-${index}`}>
              <Event
                endDateFormatted={event.endDateFormatted}
                endDateTimeFormat={event.endDateTimeFormat}
                imageDesktopAlt={event.imageDesktopAlt}
                imageDesktopUrl={event.imageDesktopUrl}
                imageMobileAlt={event.imageMobileAlt}
                imageMobileUrl={event.imageMobileUrl}
                imageTabletAlt={event.imageTabletAlt}
                imageTabletUrl={event.imageTabletUrl}
                informationBottomLeft={event.informationBottomLeft}
                informationBottomRight={event.informationBottomRight}
                numDays={event.numDays}
                startDateFormatted={event.startDateFormatted}
                startDateTimeFormat={event.startDateTimeFormat}
                title={event.title}
                url={event.url}
              />
            </Fade>
          ))}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicEventsPage {
      data {
        page_heading {
          text
        }
        body_copy {
          html
        }
        no_exhibitions_text {
          html
        }
        past_exhibitions
      }
    }
    allPrismicEvent {
      edges {
        node {
          data {
            title {
              text
            }
            start_date(formatString: "DD-MM-YYYY")
            end_date(formatString: "DD-MM-YYYY")
            url {
              url
            }
            image_desktop {
              alt
              url
            }
            image_mobile {
              alt
              url
            }
            image_tablet {
              alt
              url
            }
            enabled
            information_bottom_left {
              html
            }
            information_bottom_right {
              html
            }
          }
          id
        }
      }
    }
  }
`

const PastExhibitionsHeading = styled.h3`
  flex-shrink: 0;
  font-family: 'Aktifo B Book', sans-serif;
  font-size: 18.3pt;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 4rem 0;
  text-transform: uppercase;
  width: 100%;
`
